import React from 'react';
import _get from 'lodash/get';
import {
  ContentfulLivePreviewProvider,
  useContentfulLiveUpdates,
} from '@contentful/live-preview/react';

// components
import {
  LandingPageTemplate,
  CustomerStoryTemplate,
  UseCaseTemplate,
  PartnerDirectoryTemplate,
  PartnerPageTemplate,
  BlogPostTemplate,
  ProductPageTemplate,
  CareersPageTemplate,
  EventPageTemplate,
  ArticleTemplate,
  MainTemplate,
  mainTemplateMetaTagAdapter,
  mainTemplateStylesAdapter,
} from 'src/templates';
import { LocalizedStaticTemplate } from 'src/components/CustomerStories/LocalizedStaticTemplate';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';

//localized pages
import WhatIsPlaid from 'src/pages/what-is-plaid';
import WhyPlaidIsInvolved from 'src/pages/why-is-plaid-involved';
import ContactThanks from 'src/pages/contact-thanks';
import Contact from 'src/pages/contact';
import DeveloperPolicy from 'src/pages/developer-policy';
import DiscoverApps from 'src/pages/discover-apps';
import Global from 'src/pages/global';
import HowItWorks from 'src/pages/how-it-works-for-consumers';
import HowWeHandleData from 'src/pages/how-we-handle-data';
import OpenBanking from 'src/pages/open-banking';
import Security from 'src/pages/security';
import FourOhFour from 'src/pages/404';

// helpers
import {
  Contentful,
  ContentfulTemplates,
  StaticTemplates,
  isDevelopment,
  isProduction,
  isTesting,
} from 'src/lib/constants';
import {
  TemplateFieldShape,
  ContentfulSystemMetadata,
} from 'src/lib/prop-types';
import { getLocale } from 'src/lib/utils';
import { PreviewContext } from 'src/contexts';

const propTypes = {
  fields: TemplateFieldShape,
  sys: ContentfulSystemMetadata,
};

const Switch = (props) => {
  const templateType = _get(
    props,
    Contentful.CONTENT_TYPE_ID,
    props?.params?.template, // fallback to hard coded template for static localized pages
  );
  const locale = getLocale(props);
  switch (templateType) {
    case ContentfulTemplates.LANDING_PAGE:
      return <LandingPageTemplate {...props} locale={locale} />;
    case ContentfulTemplates.CUSTOMER_STORY:
      return <CustomerStoryTemplate {...props} />;
    case ContentfulTemplates.PARTNER_DIRECTORY:
      return <PartnerDirectoryTemplate {...props} />;
    case ContentfulTemplates.PARTNER_PAGE:
      return <PartnerPageTemplate {...props} />;
    case ContentfulTemplates.USE_CASE:
      return <UseCaseTemplate {...props} locale={locale} />;
    case ContentfulTemplates.BLOG_POST:
      return <BlogPostTemplate {...props} />;
    case ContentfulTemplates.PRODUCT_PAGE:
      return <ProductPageTemplate {...props} locale={locale} />;
    case ContentfulTemplates.CAREERS_PAGE:
      return <CareersPageTemplate {...props} />;
    case ContentfulTemplates.EVENT:
      return <EventPageTemplate {...props} />;
    case ContentfulTemplates.ARTICLE:
      return <ArticleTemplate {...props} />;
    case StaticTemplates.WHAT_IS_PLAID:
      return <WhatIsPlaid {...props} locale={locale} />;
    case StaticTemplates.WHY_PLAID_IS_INVOLVED:
      return <WhyPlaidIsInvolved {...props} locale={locale} />;
    case StaticTemplates.CONTACT_THANKS:
      return <ContactThanks {...props} locale={locale} />;
    case StaticTemplates.CONTACT:
      return <Contact {...props} locale={locale} />;
    case StaticTemplates.DEVELOPER_POLICY:
      return <DeveloperPolicy {...props} locale={locale} />;
    case StaticTemplates.DISCOVER_APPS:
      return <DiscoverApps {...props} locale={locale} />;
    case StaticTemplates.GLOBAL:
      return <Global {...props} locale={locale} />;
    case StaticTemplates.HOW_IT_WORKS:
      return <HowItWorks {...props} locale={locale} />;
    case StaticTemplates.HOW_WE_HANDLE_DATA:
      return <HowWeHandleData {...props} locale={locale} />;
    case StaticTemplates.OPEN_BANKING:
      return <OpenBanking {...props} locale={locale} />;
    case StaticTemplates.SECURITY:
      return <Security {...props} locale={locale} />;
    case StaticTemplates.CUSTOMER_STORIES.ALTO:
    case StaticTemplates.CUSTOMER_STORIES.ASTRA:
    case StaticTemplates.CUSTOMER_STORIES.BRIGIT:
    case StaticTemplates.CUSTOMER_STORIES.CARVANA:
    case StaticTemplates.CUSTOMER_STORIES.DROP:
    case StaticTemplates.CUSTOMER_STORIES.METAL:
    case StaticTemplates.CUSTOMER_STORIES.PLACID_EXPRESS:
    case StaticTemplates.CUSTOMER_STORIES.PROSPER:
    case StaticTemplates.CUSTOMER_STORIES.QAPITAL:
    case StaticTemplates.CUSTOMER_STORIES.SHIFT:
    case StaticTemplates.CUSTOMER_STORIES.SOLO:
    case StaticTemplates.CUSTOMER_STORIES.VARO:
    case StaticTemplates.CUSTOMER_STORIES.WAVE:
      return (
        <LocalizedStaticTemplate
          {...props}
          locale={locale}
          templateType={templateType}
        />
      );
    case ContentfulTemplates.PAGE:
      return (
        <MainTemplate
          locale={locale}
          {...mainTemplateStylesAdapter(props)}
          {...mainTemplateMetaTagAdapter(props)}
        >
          <CmsComponentSwitch
            components={props?.fields?.components}
            templatePage={ContentfulTemplates.PAGE}
          />
        </MainTemplate>
      );
    default:
      if (isDevelopment || isTesting) {
        return (
          <>
            <p>
              <b>Error:</b> No template matching&nbsp;
              <b>
                <i>{templateType}</i>
              </b>
            </p>
            <p>How can you fix this?</p>
            <ul>
              <li>
                Check that all required fields are filled in, for example, meta
                title, description, url, and at least one valid component
              </li>
              <li>
                Sometimes this happens due to a bad preview cookie. Clear the{' '}
                <b>__next_preview_data</b> cookie and try refreshing your
                browser.
              </li>
            </ul>
          </>
        );
      } else {
        return <FourOhFour />;
      }
  }
};

const TemplateSwitch = (staticProps) => {
  const props = useContentfulLiveUpdates(staticProps);
  // NOTE: while developing locally and using the live preview mode sometimes your page
  // will not load when you manually navigate to it. If this happens the solution (for now) is to delete
  // the next.js preview cookie
  return (
    <PreviewContext.Provider
      value={{ preview: props?.preview || false, previewData: {} }}
    >
      <ContentfulLivePreviewProvider
        locale={props?.sys?.locale || 'en-US'}
        enableInspectorMode={!isProduction}
        enableLiveUpdates={!isProduction}
      >
        <Switch {...props} />
      </ContentfulLivePreviewProvider>
    </PreviewContext.Provider>
  );
};
TemplateSwitch.propTypes = propTypes;

export default TemplateSwitch;
