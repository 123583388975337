// TODO: https://jira.plaid.com/browse/SITE-1994
import Image from 'src/components/Image';
// Optimize How it Works page/data-source with more descriptive, "self-documenting"
// and easier to reason about at-a-glance prop names

import React from 'react';
import dynamic from 'next/dynamic';

// components
import { Link } from 'src/components/Inputs';
import PageHeader from 'src/components/headers/page-header';
import Features from 'src/components/features';
import Showcase from 'src/components/showcase';
import DefaultTemplate from 'src/templates/default';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

// helpers
import { Locales } from 'src/lib/constants';
import { getStaticData } from 'src/lib/utils';

const AnimatedSections = dynamic(
  () => import('src/components/HowItWorks/AnimatedSections'),
  {
    ssr: false,
  },
);

const HowItWorks = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    featuresData,
    appsHeading,
    appsHeading2,
    categoriesHeading,
    categoriesP,
    categoryData,
    security2Heading,
    security2P,
    security2CTA,
    security2Link,
    app2Alt,
    manageConnectionsData,
    noCostData,
    resourcesData,
  } = getStaticData({ locale, filePath: 'how-it-works-for-consumers' });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <div className='how-it-works'>
        <PageHeader {...pageHeaderData} />
        <Features {...featuresData} />
        <div className='how-it-works__apps-heading'>
          <div className='grid-container'>
            <div className='grid-x align-justify grid-margin-x grid-padding-x grid-padding-y'>
              <div className='cell small-12 medium-8 medium-offset-2 text-center'>
                {appsHeading && (
                  <h6 className='how-it-works__section-title'>{appsHeading}</h6>
                )}
                <h2 className='how-it-works__apps-heading-two'>
                  {appsHeading2}
                </h2>
              </div>
              <div className='cell small-12 medium-7 large-5 show-for-small-only'>
                <h6>01</h6>
                <h4>{categoriesHeading}</h4>
                <p>{categoriesP}</p>
              </div>
            </div>
          </div>
        </div>
        <section className='how-it-works__category-arc-section'>
          <div className='how-it-works__category-arc-content'>
            <div className='grid-container'>
              <div className='grid-x align-justify grid-margin-x grid-padding-y'>
                <div className='cell small-12 medium-7 large-5 show-for-medium'>
                  <h6>01</h6>
                  <h4 className='how-it-works__heading'>{categoriesHeading}</h4>
                  <p>{categoriesP}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='how-it-works__category-arc'>
            <div>
              {categoryData.map((item, i) => (
                <React.Fragment key={`cat-${i}`}>
                  <div
                    className={
                      'how-it-works__category-arc-icon how-it-works__category-arc-icon--' +
                      item['color']
                    }
                  ></div>
                </React.Fragment>
              ))}
            </div>
            <div>
              {categoryData.map((item, i) => (
                <React.Fragment key={i}>
                  <div className='how-it-works__category-arc-icon-text'>
                    {item['title']}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
        <AnimatedSections />
        <section className='how-it-works__phone-section'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-y'>
              <div className='cell small-12 large-5 align-self-middle'>
                <h6>05</h6>
                <h4 className='how-it-works__heading'>{security2Heading}</h4>
                <p>{security2P}</p>
                <Link href={security2Link}>
                  <a>
                    <span className='feature-section-link-text'>
                      {security2CTA} <ArrowRightSingleSmall />
                    </span>
                  </a>
                </Link>
              </div>
              <div className='  cell small-8 small-push-2 large-4 large-push-1 align-self-middle text-center large-text-left  '>
                <div className='how-it-works__security-screen'>
                  <Image
                    src='/assets/img/phones/device-app-screen-success.png'
                    alt={app2Alt}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='manage-connections'>
          <Showcase {...manageConnectionsData} />
        </div>
        <div className='no-cost'>
          <Showcase {...noCostData} />
        </div>
        <Features {...resourcesData} />
      </div>
    </DefaultTemplate>
  );
};

export default HowItWorks;
