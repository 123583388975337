import React from 'react';

import DefaultTemplate from 'src/templates/default';
import PageHeader from 'src/components/headers/page-header';
import GlobalTable from 'src/components/global-table';
import Cta from 'src/components/cta';
import { Button } from 'src/components/Inputs';

// helpers
import { Locales } from 'src/lib/constants';
import { getStaticData } from 'src/lib/utils';

const PostSubmitContent = (props) => {
  return (
    <div className='cell small-12 medium-6'>
      {props['post-submit-header'] && <h2>{props['post-submit-header']}</h2>}
      {props['post-submit-subheader'] && (
        <h4 className='regular'>{props['post-submit-subheader']}</h4>
      )}
      {props['post-submit-action-text'] && (
        <Button
          className='button-cta mb1'
          href={props['post-submit-action-link']}
          fullWidth
          secondary
        >
          {props['post-submit-action-text']}
        </Button>
      )}
    </div>
  );
};

const Global = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    globalTableData,
    globalTableBetaCountriesData,
    ctaData,
  } = getStaticData({
    locale,
    filePath: 'global',
  });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <PageHeader {...pageHeaderData} />
      <GlobalTable
        {...globalTableData}
        tableClassName='grid-container section-container pb0'
      />
      <GlobalTable
        {...globalTableBetaCountriesData}
        tableClassName='grid-container section-container'
      />
      <Cta
        {...ctaData}
        postSubmitContent={<PostSubmitContent {...ctaData} />}
      />
    </DefaultTemplate>
  );
};

export default Global;
