import React from 'react';

import DefaultTemplate from 'src/templates/default';
import PageHeader from 'src/components/headers/page-header';
import ContentBlockSection from 'src/components/Layout/ContentBlockSection/content-block-section';
import Cta from 'src/components/cta';
import FeatureSection from 'src/components/features/feature-section-usecases';
import RegulatorySection from 'src/components/regulatory';

import { Locales } from 'src/lib/constants';
import { getStaticData } from 'src/lib/utils';

const OpenBanking = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    contentBlock1Data,
    ctaData,
    contentBlock2Data,
    featuresData,
    regulatoryData,
  } = getStaticData({ locale, filePath: 'open-banking' });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <PageHeader {...pageHeaderData} />
      {contentBlock1Data.map((content, i) => (
        <ContentBlockSection {...content} key={`content-${i}`} />
      ))}
      <Cta {...ctaData} />
      <ContentBlockSection {...contentBlock2Data} />
      <FeatureSection {...featuresData} />
      <RegulatorySection {...regulatoryData} />
    </DefaultTemplate>
  );
};

export default OpenBanking;
