import React from 'react';

import PageHeader from 'src/components/headers/page-header';
import Showcase from 'src/components/showcase';
import { IntroSection } from 'src/components/Layout/IntroSection';
import Features from 'src/components/features';
import FeatureSectionUsecases from 'src/components/features/feature-section-usecases';
import Footer from 'src/components/footer';
import { SupportingText } from 'src/components/Layout/SupportingText';

import { Locales } from 'src/lib/constants';

import DefaultTemplate from 'src/templates/default';
import { getStaticData } from 'src/lib/utils';

const WhyPlaidIsInvolved = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    introSectionData,
    supportingTextData,
    appsData,
    featuresData,
    dataAccessData,
    featureSectionData,
    footerData,
    troubleConnectingData,
  } = getStaticData({ locale, filePath: 'why-is-plaid-involved' });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <PageHeader {...pageHeaderData} />
      <IntroSection {...introSectionData} />
      <SupportingText {...supportingTextData} />
      <Showcase {...appsData} key={`why-plaid-apps-0`} />
      <Showcase {...troubleConnectingData} />
      <FeatureSectionUsecases {...featureSectionData} />
      <Showcase {...dataAccessData} />
      <Features {...featuresData} />
      <Footer {...footerData} />
    </DefaultTemplate>
  );
};

export default WhyPlaidIsInvolved;
