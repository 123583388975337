import React, { useState, useEffect } from 'react';

import DefaultTemplate from 'src/templates/default';
import PageHeader from 'src/components/headers/page-header';
import Showcase from 'src/components/showcase';
import FeatureSectionUsecases from 'src/components/features/feature-section-usecases';
import CustomerAppCollection from 'src/components/customer-app-collection';

import { Locales } from 'src/lib/constants';
import { getStaticData } from 'src/lib/utils';
import { useRouter } from 'next/router';

const WhatIsPlaid = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    showcaseData1,
    featureSectionData,
    showcaseData2,
    customerAppCollectionData,
  } = getStaticData({ locale, filePath: 'what-is-plaid' });

  const router = useRouter();
  const [showHeaderDisclaimer, setShowHeaderDisclaimer] = useState(false);

  useEffect(() => {
    if (router.query.showLegalDisclaimer === 'true') {
      setShowHeaderDisclaimer(true);
    }
  }, [router.query.showLegalDisclaimer]);

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <React.Fragment>
        <PageHeader {...pageHeaderData} showDisclaimer={showHeaderDisclaimer} />

        {showcaseData1.map((item, i) => {
          return (
            <React.Fragment key={`item-${i}`}>
              <Showcase {...item} />
            </React.Fragment>
          );
        })}

        <FeatureSectionUsecases {...featureSectionData} />

        {showcaseData2.map((item, i) => {
          return (
            <React.Fragment key={`item-${i}`}>
              <Showcase {...item} />
            </React.Fragment>
          );
        })}

        <CustomerAppCollection {...customerAppCollectionData} />
      </React.Fragment>
    </DefaultTemplate>
  );
};

export default WhatIsPlaid;
