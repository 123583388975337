import React from 'react';
import PropTypes from 'prop-types';

// components
import { Link } from 'src/components/Inputs';
import Checkmark from 'src/components/SVGs/Icons/Checkmark';
import Image from 'src/components/Image';

const propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        img: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(
          PropTypes.shape({
            feature: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            locale: PropTypes.string,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    ).isRequired,
  ).isRequired,
  tableClassName: PropTypes.string.isRequired,
};

const GlobalTable = ({ title, tableData, tableClassName }) => (
  <div className='background background--black100'>
    <div className={tableClassName}>
      <div className='grid-x'>
        <div className='cell'>
          <div className='items-table-layout items-table-layout--global'>
            <h4>{title}</h4>

            {tableData.map((tableItem, i) => (
              <div className='items-table-layout__group' key={`tableItem-${i}`}>
                <div className='grid-x'>
                  {tableItem.map((groupItem, i) => {
                    return (
                      <div
                        className='cell small-12 medium-6 large-4'
                        key={`groupItem-${i}`}
                      >
                        <div className='items-table items-table--global'>
                          <div className='items-table-list items-table-list--global'>
                            <h6
                              className={`items-table-list-header items-table-list-header--global`}
                            >
                              <Image
                                src={groupItem.img}
                                aria-hidden='true'
                                alt={groupItem.alt}
                              />{' '}
                              <span>{groupItem.label}</span>
                            </h6>
                            {groupItem.products.map((product, i) => {
                              if (!product.url) {
                                return (
                                  <div
                                    /* use `has-feature` class only to fix text color */
                                    className='items-table-list-item has-feature'
                                    key={`product-${i}`}
                                  >
                                    <p className='items-table-list-item-text'>
                                      {product.feature}
                                    </p>
                                  </div>
                                );
                              }

                              return (
                                <div
                                  className='items-table-list-item'
                                  key={`product-${i}`}
                                >
                                  <span className='check-icon'>
                                    <Checkmark />
                                  </span>
                                  <p className='items-table-list-item-text'>
                                    <Link
                                      href={product.url}
                                      locale={product?.locale || ''}
                                    >
                                      <a>{product.feature}</a>
                                    </Link>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

GlobalTable.propTypes = propTypes;
export default GlobalTable;
