// compiler-disable-overwrite
import React from 'react';

import PageHeader from 'src/components/headers/page-header';
import Showcase from 'src/components/showcase';
import Footer from 'src/components/footer';
import Features from 'src/components/features';
import TextBlock from 'src/components/text-block';

import DefaultTemplate from 'src/templates/default';
import { Locales } from 'src/lib/constants';
import { getStaticData } from 'src/lib/utils';

const DiscoverApps = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    appsData,
    featuresData,
    textBlockData,
    footerData,
  } = getStaticData({ locale, filePath: 'discover-apps' });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <PageHeader {...pageHeaderData} />
      <div className='discover-apps__showcases'>
        {appsData.map((item, i) => (
          <Showcase
            {...item}
            key={`app-category-${i}`}
            nthChild={(i + 1) % 2 === 0 ? 'even' : 'odd'}
          />
        ))}
      </div>
      <TextBlock {...textBlockData} />
      <Features {...featuresData} />
      <Footer {...footerData} />
    </DefaultTemplate>
  );
};

export default DiscoverApps;
